import { Box, Typography, Button, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { Public, AttachMoney, FlashOn } from "@mui/icons-material"; // Importing material icons
import { Link } from "react-router-dom";

const WhyChooseUs = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box
            sx={{
                width: "100%",
                minHeight: "100vh",
                padding: "40px 20px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "40px",
            }}
        >
            {/* Header Section */}
            <Box
                sx={{
                    textAlign: "center",
                    padding: "20px",
                    maxWidth: "800px",
                }}
            >
                <Typography
                    variant="h2"
                    sx={{
                        fontWeight: "bold",
                        marginBottom: "10px",
                        color: colors.grey[100],
                    }}
                >
                    Why Choose Travel Data eSIM?
                </Typography>
                <Typography
                    variant="h6"
                    sx={{
                        fontSize: "1.2rem",
                        color: colors.greenAccent[400],
                        opacity: 0.8,
                    }}
                >
                    Global Connectivity Made Effortless
                </Typography>
            </Box>

            {/* Main Content Section */}
            <Box
                sx={{
                    maxWidth: "1200px",
                    display: "grid",
                    gridTemplateColumns: { xs: "1fr", md: "1fr 1fr 1fr" },
                    gap: "40px",
                }}
            >
                {/* Feature 1 */}
                <Box
                    sx={{
                        textAlign: "center",
                        padding: "20px",
                        background: `linear-gradient(145deg, ${colors.grey[800]} 10%, ${colors.grey[900]} 90%)`,
                        borderRadius: "12px",
                        boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.2)",
                    }}
                >
                    <Public sx={{ fontSize: "40px", color: colors.greenAccent[400], marginBottom: "20px" }} /> {/* Icon for Global Coverage */}
                    <Typography
                        variant="h4"
                        sx={{
                            fontWeight: "bold",
                            marginBottom: "20px",
                            color: colors.grey[100],
                        }}
                    >
                        Global Coverage
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            fontSize: "1rem",
                            lineHeight: "1.8",
                            color: colors.grey[100],
                            opacity: 0.9,
                        }}
                    >
                        Stay connected with eSIMs for over 200+ countries and regions. From bustling cities to remote 
                        destinations, Travel Data eSIM keeps you online wherever your journey takes you.
                    </Typography>
                </Box>

                {/* Feature 2 */}
                <Box
                    sx={{
                        textAlign: "center",
                        padding: "20px",
                        background: `linear-gradient(145deg, ${colors.grey[800]} 10%, ${colors.grey[900]} 90%)`,
                        borderRadius: "12px",
                        boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.2)",
                    }}
                >
                    <AttachMoney sx={{ fontSize: "40px", color: colors.greenAccent[400], marginBottom: "20px" }} /> {/* Icon for Cost-Effective Solutions */}
                    <Typography
                        variant="h4"
                        sx={{
                            fontWeight: "bold",
                            marginBottom: "20px",
                            color: colors.grey[100],
                        }}
                    >
                        Cost-Effective Solutions
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            fontSize: "1rem",
                            lineHeight: "1.8",
                            color: colors.grey[100],
                            opacity: 0.9,
                        }}
                    >
                        Say goodbye to hidden charges and high roaming fees. Choose affordable, flexible plans tailored 
                        to your needs, so you only pay for what you use—without sacrificing quality.
                    </Typography>
                </Box>

                {/* Feature 3 */}
                <Box
                    sx={{
                        textAlign: "center",
                        padding: "20px",
                        background: `linear-gradient(145deg, ${colors.grey[800]} 10%, ${colors.grey[900]} 90%)`,
                        borderRadius: "12px",
                        boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.2)",
                    }}
                >
                    <FlashOn sx={{ fontSize: "40px", color: colors.greenAccent[400], marginBottom: "20px" }} /> {/* Icon for Instant Activation */}
                    <Typography
                        variant="h4"
                        sx={{
                            fontWeight: "bold",
                            marginBottom: "20px",
                            color: colors.grey[100],
                        }}
                    >
                        Instant Activation
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            fontSize: "1rem",
                            lineHeight: "1.8",
                            color: colors.grey[100],
                            opacity: 0.9,
                        }}
                    >
                        No more waiting at the airport! Activate your eSIM instantly by scanning a QR code and get 
                        immediate access to data services as soon as you arrive at your destination.
                    </Typography>
                </Box>
            </Box>

            {/* Call to Action */}
            <Box
                sx={{
                    textAlign: "center",
                    marginTop: "40px",
                }}
            >
                <Typography
                    variant="h5"
                    sx={{
                        marginBottom: "20px",
                        color: colors.grey[100],
                    }}
                >
                    With Travel Data eSIM, you choose reliability, affordability, and convenience.
                </Typography>
                <Link to="/buy-esim" style={{ textDecoration: "none" }}>
                    <Button
                        variant="contained"
                        size="large"
                        sx={{
                            padding: "12px 40px",
                            fontSize: "1rem",
                            backgroundColor: theme.palette.primary.main,
                            color: colors.grey[900],
                            "&:hover": {
                                backgroundColor: theme.palette.primary.dark,
                            },
                        }}
                    >
                        Get Started
                    </Button>
                </Link>
            </Box>
        </Box>
    );
};

export default WhyChooseUs;
