import React from 'react';
import { Box, Grid, Typography, Button, useTheme } from "@mui/material";
import { countryData } from '../data/countryData';
import { tokens } from '../theme'; // Import tokens for theme colors
import { useNavigate } from "react-router-dom";

const CountryGrid = () => {
    const displayedCountries = countryData.slice(0, 20); // Display first 20 countries
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const navigate = useNavigate();

    const handleAllCountriesListClick = () => {
        navigate("/buy-esim");
    };

    function handleClick(country){
        if(country === 'UAE') {
            country = 'United Arab Emirates';
        }else if(country === 'USA')
        {
            country = 'United States';
        }
        // Navigate to buy-esim page with the input as a parameter
        navigate(`/buy-esim?country=${encodeURIComponent(country)}`);
    };

    return (
        <Box>
            <Grid container spacing={3}>
                {displayedCountries.map((country) => (
                    <Grid item xs={6} sm={3} md={3} lg={3} key={country.id}>
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="flex-start"
                            p={2.5}
                            border="1px solid #ccc"
                            borderRadius="8px"
                            textAlign="left"
                            backgroundColor={colors.tough[100]}
                            sx={{
                                paddingLeft: '16px',
                                paddingRight: '16px',
                                boxShadow: theme.palette.mode === 'dark'
                                    ? '2px 2px 8px rgba(255, 255, 255, 0.2)'
                                    : '2px 2px 8px rgba(0, 0, 0, 0.2)',

                            }}
                            onClick={() => handleClick(country.name)}
                        >
                            <img
                                src={country.flag}
                                alt={`${country.name} flag`}
                                style={{ width: '40px', height: '25px', marginRight: '10px' }}
                            />
                            <Typography variant="body">{country.name}</Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>
            <Box display="flex" justifyContent="center" mt={3}>
                <Button
                    variant="contained"
                    style={{
                        backgroundColor: theme.palette.mode === 'dark' ? colors.grey[100] : theme.palette.primary.main,
                        color: theme.palette.mode === 'dark' ? colors.primary[900] : '#fff'
                    }}
                    onClick={handleAllCountriesListClick}
                >
                    Show All Countries
                </Button>
            </Box>
        </Box>
    );
};

export default CountryGrid;
