import React from 'react';
import { Box, Typography, Divider, List, ListItem, ListItemText } from '@mui/material';
import './TermsAndConditions.css';

const TermsAndConditions = () => {

    return (
        <Box m="20px">
            <Box display="flex" justifyContent="center" alignItems="center">
                <Box className="termsAndConditions">
                    <Typography variant="h2" fontWeight={700} mb={3}>
                        Privacy Policy
                    </Typography>
                    <Typography mb={2} textAlign="left" width="100%">
                        <strong>Last updated: </strong>29/11/2024
                    </Typography>
                    <Typography mb={3} textAlign="justify">
                        This Privacy Policy outlines how <strong>Travel Data eSIM</strong> collects, uses, and protects your personal information when you access our website, use our services, or communicate with us (collectively referred to as "Services"). This policy applies to users, customers, and visitors of the Site. By using our Services, you consent to the practices described in this Privacy Policy. If you do not agree, please refrain from accessing or using our Services. 
                    </Typography>

                    <Box className="serviceLeadingSection">
                        <Box className="serviceInfoContainer">
                            <Typography className="serviceLead">Changes to This Privacy Policy </Typography>
                            <Typography className="serviceDetails">We may periodically update this Privacy Policy to reflect changes to our practices, legal requirements, or operational needs. Changes will be posted on this page, and the "Last Updated" date will be revised. We encourage you to review the Privacy Policy periodically to stay informed. </Typography>
                            <Divider
                                className={`secionLine lineColorOrange`}
                            />
                        </Box>
                    </Box>
                    
                    <Box className="serviceLeadingSection">
                        <Box className="serviceInfoContainer">
                            <Typography className="serviceLead">Information We Collect </Typography>
                            <Typography className="serviceDetails">The type and scope of personal information collected depend on how you interact with the Site: </Typography>
                            <Divider
                                className={`secionLine lineColorGreen`}
                            />
                            <Typography className="serviceLead">Information You Provide Directly </Typography>
                            <Typography className="serviceDetails">We collect information when you: </Typography>
                            <List className='serviceDetails'>
                                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                    <ListItemText primary={<><strong>Register or Create an Account:</strong> Includes your name, email address, phone number, username, and password. </>} />
                                </ListItem>
                                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                    <ListItemText primary={<><strong>Make Purchases:</strong> Includes billing address, payment confirmation, and purchase details. </>} />
                                </ListItem>
                                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                    <ListItemText primary={<><strong>Contact Support:</strong> Any information shared via emails, chat, or web forms for troubleshooting or inquiries. </>} />
                                </ListItem>
                                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                    <ListItemText primary={<><strong>Engage in Activities:</strong> Includes survey responses, contest participation, or reviews you post. </>} />
                                </ListItem>
                            </List>
                            <Typography className="serviceLead">Information We Collect Automatically </Typography>
                            <Typography className="serviceDetails">When you use the Site, we may automatically collect: </Typography>
                            <List className='serviceDetails'>
                                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                    <ListItemText primary={<><strong>Device Information:</strong> Includes IP address, browser type, and device details. </>} />
                                </ListItem>
                                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                    <ListItemText primary={<><strong>Usage Information:</strong> Pages visited, actions on the Site (e.g., items viewed, added to the cart), and time spent on pages. </>} />
                                </ListItem>
                                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                    <ListItemText primary={<><strong>Cookies and Tracking:</strong> Cookies, pixels, and similar technologies collect analytics to enhance the user experience. </>} />
                                </ListItem>
                            </List>
                            <Typography className="serviceLead">Information from Third Parties </Typography>
                            <Typography className="serviceDetails">We may receive information from: </Typography>
                            <List className='serviceDetails'>
                                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                    <ListItemText primary={<><strong>Service Providers:</strong> Payment processors, shipping services, and IT vendors. </>} />
                                </ListItem>
                                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                    <ListItemText primary={<><strong>Marketing Partners:</strong> For customer acquisition and promotional activities. </>} />
                                </ListItem>
                                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                    <ListItemText primary={<><strong>Social Media Integrations:</strong> Data shared through linked social media accounts. </>} />
                                </ListItem>
                            </List>
                        </Box>
                    </Box>
                    
                    <Box className="serviceLeadingSection">
                        <Box className="serviceInfoContainer">
                            <Typography className="serviceLead">How We Use Your Personal Information </Typography>
                            <Typography className="serviceDetails">We use the information collected for: </Typography>
                            <Divider
                                className={`secionLine lineColorPurple`}
                            />
                            <List className='serviceDetails'>
                                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='listNum'>1.</span>
                                    <ListItemText primary={<><strong>Providing Services:</strong> To process orders, manage accounts, deliver products, and facilitate returns or exchanges. </>} />
                                </ListItem>
                                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='listNum'>2.</span>
                                    <ListItemText primary={<><strong>Improving the Experience:</strong> Enhance navigation, optimize site functionality, and improve product recommendations. </>} />
                                </ListItem>
                                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='listNum'>3.</span>
                                    <ListItemText primary={<><strong>Communication:</strong> Responding to inquiries, sending order updates, and providing account notifications. </>} />
                                </ListItem>
                                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='listNum'>4.</span>
                                    <ListItemText primary={<><strong>Marketing and Promotions:</strong> Tailoring ads and promotions based on preferences or past purchases. </>} />
                                </ListItem>
                                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='listNum'>5.</span>
                                    <ListItemText primary={<><strong>Legal and Security:</strong> Detecting fraud, ensuring compliance with laws, and safeguarding the Site against threats. </>} />
                                </ListItem>
                            </List>
                        </Box>
                    </Box>

                    <Box className="serviceLeadingSection">
                        <Box className="serviceInfoContainer">
                            <Typography className="serviceLead">Disclosure of Information </Typography>
                            <Typography className="serviceDetails">Your personal information may be shared under the following circumstances: </Typography>
                            <Divider
                                className={`secionLine lineColorBlue`}
                            />
                            <List className='serviceDetails'>
                                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                    <ListItemText primary={<><strong>With Vendors:</strong> To process payments, provide customer support, or manage order fulfillment. </>} />
                                </ListItem>
                                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                    <ListItemText primary={<><strong>For Legal Compliance:</strong> To comply with regulatory obligations or respond to lawful requests. </>} />
                                </ListItem>
                                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                    <ListItemText primary={<><strong>Corporate Transactions:</strong> If the company undergoes a merger, acquisition, or sale. </>} />
                                </ListItem>
                            </List>
                            <Typography className="serviceDetails">We do not sell or share sensitive personal information for profiling or marketing purposes. </Typography>
                        </Box>
                    </Box>

                    <Box className="serviceLeadingSection">
                        <Box className="serviceInfoContainer">
                            <Typography className="serviceLead">Your Rights </Typography>
                            <Typography className="serviceDetails">Depending on your location, you may have the right to: </Typography>
                            <Divider
                                className={`secionLine lineColorOrange`}
                            />
                            <List className='serviceDetails'>
                                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='listNum'>1.</span>
                                    <ListItemText primary={<><strong>Access and Portability:</strong> Request access to personal data and a copy for transfer purposes. </>} />
                                </ListItem>
                                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='listNum'>2.</span>
                                    <ListItemText primary={<><strong>Rectification:</strong> Correct inaccurate or incomplete data. </>} />
                                </ListItem>
                                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='listNum'>3.</span>
                                    <ListItemText primary={<><strong>Erasure:</strong> Request deletion of personal data, subject to legal or contractual requirements. </>} />
                                </ListItem>
                                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='listNum'>4.</span>
                                    <ListItemText primary={<><strong>Restriction:</strong> Limit certain processing activities. </>} />
                                </ListItem>
                            </List>
                            <Typography className="serviceDetails">To exercise your rights, please contact us using the details below. We may request additional information to verify your identity. </Typography>
                        </Box>
                    </Box>

                    <Box className="serviceLeadingSection">
                        <Box className="serviceInfoContainer">
                            <Typography className="serviceLead">Third-Party Websites </Typography>
                            <Typography className="serviceDetails">Our Site may contain links to third-party websites. We are not responsible for the privacy practices or content of these external sites. Please review their policies before sharing information. </Typography>
                            <Divider
                                className={`secionLine lineColorGreen`}
                            />
                        </Box>
                    </Box>

                    <Box className="serviceLeadingSection">
                        <Box className="serviceInfoContainer">
                            <Typography className="serviceLead">Contact Us </Typography>
                            <Typography className="serviceDetails">If you have questions about this Privacy Policy or wish to exercise your rights, contact us at: </Typography>
                            <Divider
                                className={`secionLine lineColorPurple`}
                            />
                            <Typography className="serviceDetails"><strong>Email:</strong></Typography>
                            <Typography className="serviceDetails"><strong>Mailing Address:</strong>Travel Data eSIM,</Typography>
                        </Box>
                    </Box>

                </Box>
            </Box>
        </Box>
    );
};

export default TermsAndConditions;
