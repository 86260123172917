import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Typography, Grid, FormControl, InputLabel, Select, MenuItem, Pagination } from '@mui/material';
import PackageCard from '../../components/packageCard';
import { useNavigate, useSearchParams } from 'react-router-dom';

const ShopPage = () => {
    const [searchParams] = useSearchParams();
    const [products, setProducts] = useState([]);
    const [regions, setRegions] = useState([]);
    const [countries, setCountries] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('');
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const navigate = useNavigate();

    const createSlug = (text) => text.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');

    const country = searchParams.get("country");
    useEffect(() => {
        if (country) {
            setSelectedCountry(country);
        }
    }, [country]);

    // Function to get filtered products based on selected region and country
    const getFilteredProducts = (products) => {
        return products.filter((product) => {
            const country = product.country || {}; // Add safety check to prevent errors from null/undefined country
            const isRegionMatch = selectedRegion ? country.region === selectedRegion : true;
            const isCountryMatch = selectedCountry ? country.name === selectedCountry : true;
            return isRegionMatch && isCountryMatch;
        });
    };

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/products`);
                const fetchedProducts = response.data;
                setProducts(fetchedProducts);

                // Extract unique regions and countries from the fetched products
                const regionsSet = new Set(fetchedProducts.map(product => product.country?.region).filter(Boolean));
                const countriesSet = new Set(fetchedProducts.map(product => product.country?.name).filter(Boolean));

                const sortedRegions = [...regionsSet].sort();
                const sortedCountries = [...countriesSet].sort();

                setRegions(sortedRegions);
                setCountries(sortedCountries);

                // Update totalPages based on filtered products
                const filteredProducts = getFilteredProducts(fetchedProducts);
                setTotalPages(Math.ceil(filteredProducts.length / 16)); // 16 products per page
            } catch (error) {
                console.error('Error fetching products', error);
            }
        };

        fetchProducts();
    }, [selectedRegion, selectedCountry]);

    const handleRegionChange = (event) => {
        setSelectedRegion(event.target.value);
        setSelectedCountry(''); // Reset country filter when region changes
        setPage(1); // Reset to the first page
    };

    const handleCountryChange = (event) => {
        setSelectedCountry(event.target.value);
        setPage(1); // Reset to the first page
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handleBuyNow = (id, packageName) => {
        const slug = createSlug(packageName);
        navigate(`/product/${id}-${slug}`);
    };

    // Get filtered products for pagination
    const filteredProducts = getFilteredProducts(products);

    // Paginate the filtered products
    const paginatedProducts = filteredProducts.slice((page - 1) * 16, page * 16);

    return (
        <Box p={3}>
            <Typography variant="h4" gutterBottom>
                eSIM Packages
            </Typography>

            {/* Filters */}
            <Box display="flex" justifyContent="space-between" mb={3}>
                <FormControl sx={{ width: '45%' }}>
                    <InputLabel>Country</InputLabel>
                    <Select
                        value={selectedCountry}
                        onChange={handleCountryChange}
                    >
                        <MenuItem value="">All Countries</MenuItem>
                        {countries
                            .filter(country =>
                                !selectedRegion || products.some(product => product.country?.name === country)
                            )
                            .map((country) => (
                                <MenuItem key={country} value={country}>
                                    {country}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
                <FormControl sx={{ width: '45%' }}>
                    <InputLabel>Region</InputLabel>
                    <Select
                        value={selectedRegion}
                        onChange={handleRegionChange}
                    >
                        <MenuItem value="">All Regions</MenuItem>
                        {regions.length > 0 ? (
                            regions.map((region) => (
                                <MenuItem key={region} value={region}>
                                    {region}
                                </MenuItem>
                            ))
                        ) : (
                            <MenuItem value="">No Regions Available</MenuItem>
                        )}
                    </Select>
                </FormControl>
            </Box>

            {/* Product Grid */}
            <Grid container spacing={3}>
                {paginatedProducts.map((product) => (
                    <Grid item xs={12} sm={6} md={3} key={product.id}>
                        <PackageCard product={product} onBuyNow={handleBuyNow} />
                    </Grid>
                ))}
            </Grid>

            {/* Pagination */}
            <Box display="flex" justifyContent="center" mt={3}>
                <Pagination
                    count={totalPages}
                    page={page}
                    onChange={handlePageChange}
                    color="primary"
                />
            </Box>
        </Box>
    );
};

export default ShopPage;
