import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Footer from "./scenes/global/Footer";
import Dashboard from "./scenes/dashboard/index.jsx";
import Home from "./scenes/index.jsx"
import Signup from './scenes/signup/signup.jsx'
import Login from "./scenes/login/login.jsx";
import PrivateRoute from './components/PrivateRoute';
import SingleProduct from './components/singleProduct.jsx';
import Checkout from "./components/stripeCheckout.jsx";
import CheckoutStatus from "./components/checkoutStatus.jsx";
import ESIMShop from "./scenes/shop/index.jsx";
import OurStory from "./scenes/pages/our_story.jsx"
import WhyChooseUs from "./scenes/pages/why_us.jsx"
import Impact from "./scenes/pages/impact.jsx"
import TermsAndConditions from "./scenes/pages/TermsAndConditions.jsx";
import PrivacyPolicy from "./scenes/pages/PrivacyPolicy.jsx";

function App() {
  const [theme, colorMode] = useMode();

  return( 
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Topbar />
        <main
          className="content"
          style={{
            justifyContent: 'center',
            height: `calc(96vh - 64px)`,
            overflowY: 'auto',
            padding: '20px 20px 0px 20px',
            width: '100%',
          }}>
            <div style={{ width: '100%',}}
          >
            <Routes>
              <Route path="/" element={<Home />} />
              
              <Route path="/dashboard/*" element={<PrivateRoute><Dashboard /> </PrivateRoute>} />
              <Route path='/login' element={<Login/>}></Route>
              <Route path='/signup' element={<Signup/>}></Route>
              <Route path="/product/:id" element={<SingleProduct />} />
              <Route path="/checkout/:id" element={<PrivateRoute><Checkout /></PrivateRoute>} />
              <Route path="/checkout/return" element={<PrivateRoute><CheckoutStatus /></PrivateRoute>} />
              <Route path="/buy-esim" element={<ESIMShop />} />
              <Route path="/page/our-story" element={<OurStory />} />
              <Route path="/page/why-choose-us" element={<WhyChooseUs />} />
              <Route path="/page/impact" element={<Impact />} />
              <Route path="/terms" element={<TermsAndConditions />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            </Routes>
          </div>
          <Footer />
          </main>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;