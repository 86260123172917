import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

// Function to check if the user is authenticated (i.e., has a token)
const isAuthenticated = () => {
    const jwtToken = localStorage.getItem('jwtToken');
    const apiAccessToken = localStorage.getItem('apiAccessToken');
    return !!(jwtToken && apiAccessToken); // Returns true if both tokens exist, false otherwise
};

const PrivateRoute = ({ children }) => {
    const location = useLocation();

    return isAuthenticated() ? children : <Navigate to="/login" state={{from: location}} />;
};

export default PrivateRoute;

