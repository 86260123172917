import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
    Box,
    Button
} from '@mui/material';

const CheckoutStatus = () => {
    const [searchParams] = useSearchParams();
    const session_id = searchParams.get('session_id');
    // const product_id = searchParams.get('product_id');
    const [loading, setLoading] = useState(true);
    const [complete, setComplete] = useState(null);
    const navigate = useNavigate();

    const fetchStatus = async () => {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/api/checkout/session_status?session_id=${session_id}`)
        // Parse the JSON from the response
        const session = await response.json();
        if (session.status === 'complete') {
            setComplete(true);
        } else if (session.status === 'open') {
            setComplete(false);
        }
        setLoading(false);
    };
    fetchStatus();

    const handleHomeBtn = () => {
        navigate('/');
    }
    const handleOrdersBtn = () => {
        navigate('/dashboard/order');
    }

    if (loading) return <div>Loading...</div>;

    return (
        <Box maxWidth="sm" mx="auto" my={5} p={3} border="1px solid #ddd" borderRadius="12px" boxShadow={3}>
            {complete ? (
                <>
                    <Box mt={4} display="flex" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" >
                        <Box maxWidth={64} display="flex" alignItems="center" justifyContent="center" >
                            <svg viewBox="0 0 24 24" style={{ color: "#349748", width: "100%", height: "100%" }}>
                                <path fill="currentColor"
                                    d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z">
                                </path>
                            </svg>
                        </Box>
                    </Box>
                    <Box mt={4} display="flex" flexDirection="column" textAlign="center">
                        <h3><strong>Payment Done!</strong></h3>
                        <p style={{ color: "#404b46" }}><strong>Thank you for completing your secure online payment. Your eSIM details will be emailed to your email address. Visit orders to find your order.</strong></p>
                        <p> <em>Have a great day! </em> </p>
                        <Box mt={4}>
                            <Button variant="contained" color="primary" size="large" onClick={handleOrdersBtn}>View Orders</Button>
                        </Box>
                    </Box>
                </>) : (
                <>
                    <Box mt={4} display="flex" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" >
                        <Box maxWidth={64} display="flex" alignItems="center" justifyContent="center" >
                            <svg viewBox="0 0 24 24" style={{ color: "#c54b56", width: "100%", height: "100%" }}>
                                <path fill="currentColor" d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0ZM15.54,8.46a1,1,0,0,1,0,1.42L13.41,12l2.13,2.12a1,1,0,1,1-1.42,1.42L12,13.41l-2.12,2.13a1,1,0,0,1-1.42-1.42L10.59,12,8.46,9.88a1,1,0,0,1,1.42-1.42L12,10.59l2.12-2.13A1,1,0,0,1,15.54,8.46Z" />
                            </svg>
                        </Box>
                    </Box>
                    <Box mt={4} display="flex" flexDirection="column" textAlign="center">
                        <h3><strong>Payment Canceled!</strong></h3>
                        <p style={{ color: "#404b46" }}><strong>Please try again later.</strong></p>
                        <Box mt={4}>
                            <Button variant="contained" color="primary" size="large" onClick={handleHomeBtn}>Go Home</Button>
                        </Box>
                    </Box>
                </>)}
        </Box>
    );
}

export default CheckoutStatus;