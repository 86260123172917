import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, TextField, Typography, useTheme } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';

// Yup validation schema
const validationSchema = yup.object().shape({
  firstname: yup.string().required('First Name is required'),
  lastname: yup.string().required('Last Name is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  password: yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
});

const Signup = () => {
  const [serverError, setServerError] = useState('');
  const navigate = useNavigate();
  const theme = useTheme();

  const handleFormSubmit = (values, { setSubmitting }) => {
    setServerError('');
    axios.post(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/signup`, values)
      .then(res => {
        if (res.data === "Success") {
          navigate('/login');
        } else {
          setServerError('Sign up failed, please try again.');
        }
      })
      .catch(err => {
        console.log(err);
        setServerError('An error occurred, please try again.');
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      flexDirection="column"
      bgcolor={theme.palette.background.default}
    >
      <Typography variant="h4" gutterBottom>
        Sign Up
      </Typography>
      <Formik
        initialValues={{ firstname: '', lastname: '', email: '', password: '' }}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="20px"
              gridTemplateColumns="repeat(1, 1fr)"
              sx={{ width: '300px' }}
            >
              <TextField
                fullWidth
                variant="filled"
                label="First Name"
                type="text"
                name="firstname"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.firstname}
                error={!!touched.firstname && !!errors.firstname}
                helperText={touched.firstname && errors.firstname}
              />
              <TextField
                fullWidth
                variant="filled"
                label="Last Name"
                type="text"
                name="lastname"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.lastname}
                error={!!touched.lastname && !!errors.lastname}
                helperText={touched.lastname && errors.lastname}
              />
              <TextField
                fullWidth
                variant="filled"
                label="Email"
                type="email"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
              />
              <TextField
                fullWidth
                variant="filled"
                label="Password"
                type="password"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                error={!!touched.password && !!errors.password}
                helperText={touched.password && errors.password}
              />
              {/* Display server error above the sign-up button */}
              {serverError && (
                <Typography color="error" variant="body2" sx={{ textAlign: 'center' }}>
                  {serverError}
                </Typography>
              )}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Signing up...' : 'Sign Up'}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      <Box mt={2}>
        <Typography>
          Already have an account? <Link to="/login">Login here</Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default Signup;
