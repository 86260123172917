import React, { useEffect, useState } from "react";
import { Button, Box, Typography, useTheme, CircularProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../dashboard/Header";

const CheckUsage = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isOpen, setOpen] = useState(false);
    const [iccid, setICCID] = useState('N/A');
    const [description, setDescription] = useState('N/A');
    const [initial_quantity, setInitialQuantity] = useState('N/A');
    const [remaining_quantity, setRemainingQuantity] = useState('N/A');

    const userId = localStorage.getItem('userid');

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_BACKEND_API_BASE_URL}/esims/esims/${userId}`
                );
                if (!response.ok) {
                    throw new Error("Failed to fetch orders");
                }
                const data = await response.json();
                setOrders(data); // Update state with fetched orders
            } catch (err) {
                console.error("Error fetching orders:", err.message);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchOrders();
    }, [userId]);

    const handleCheckUsage = async (iccid) => {

        setICCID(iccid);

        try {
            const response = await fetch(
                `${process.env.REACT_APP_BACKEND_API_BASE_URL}/esims/check_usage/${iccid}`,
                {
                    method: "GET",
                }
            );

            if (!response.ok) {
                throw new Error("Failed calling check usage API");
            }

            const data = await response.json();

            if (data?.products) {
                setDescription(data?.products[0]?.description ?? 'N/A');
                setInitialQuantity((data?.products[0]?.assignments[0]?.initial_quantity / 1000).toFixed(2) + 'GB' ?? 'N/A');
                setRemainingQuantity((data?.products[0]?.assignments[0]?.remaining_quantity / 1000).toFixed(2) + 'GB' ?? 'N/A');
            } else {
                setRemainingQuantity('No remaining Data exists!');
            }

            setOpen(true);

        } catch (error) {
            console.error("Error checking usage:", error.message);
        }
    };

    const closeDialog = () => {
        setOpen(false);
        setDescription('N/A');
        setInitialQuantity('N/A');
        setRemainingQuantity('N/A');
    };


    const columns = [
        { field: "id", headerName: "ESIM ID", width: 80 },
        { field: "orderId", headerName: "Order ID", width: 80 },
        { field: "iccId", headerName: "ICCID", width: 170 },
        {
            field: "packageName",
            headerName: "Package",
            flex: 1, // Ensures this column takes up remaining space
            cellClassName: "name-column--cell",
            minWidth: 170,
        },
        {
            field: "date",
            headerName: "Date",
            type: "text",
            headerAlign: "left",
            align: "left",
            width: 110,
        },
        {
            field: "actions",
            headerName: "Actions",
            width: 120,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: '#3da58a',
                        color: 'white',
                        fontSize: '0.68rem',
                        fontWeight: 'bold',
                        textTransform: 'none',
                        borderRadius: '8px',
                        padding: '8px 8px',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                        transition: 'background-color 0.3s ease',
                        '&:hover': {
                            backgroundColor: 'darkgreen',
                        },
                        margin: '2px',
                    }}
                    onClick={(event) => {
                        event.stopPropagation(); // prevent row selection
                        handleCheckUsage(params.row.iccId); // call check usage function
                    }}
                >
                    Check Usage
                </Button>
            ),
        },
    ];


    return (
        <Box m="20px">
            <Header title="Check Usage" subtitle="Bundle consumption details of your eSIMs" />
            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none", // Remove the default border on cells
                        outline: "none", // Prevent focus outline on the cell itself
                    },
                    "& .MuiDataGrid-columnHeader": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    // Prevent focus styles on cell when clicked
                    "& .MuiDataGrid-cell:focus-visible": {
                        outline: "none", // Remove any focus outline
                        boxShadow: "none", // Prevent box-shadow on cell focus
                    },
                    // Prevent border on button focus
                    "& .MuiButton-root:focus": {
                        outline: "none", // Remove outline on button focus
                        boxShadow: "none", // Remove box-shadow on button focus
                    },
                }}
            >
                {loading ? (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                    >
                        <CircularProgress color="secondary" />
                    </Box>
                ) : error ? (
                    <Typography variant="h6" color="error" textAlign="center">
                        {error}
                    </Typography>
                ) : orders.length > 0 ? (
                    <DataGrid
                        rows={orders}
                        columns={columns}
                        getRowId={(row) => row.id} // Use `id` as the row ID
                    />
                ) : (
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                        textAlign="left"
                    >
                        <img
                            src={`../../assets/no_orders_yet_searching.png`}
                            alt="No data available"
                            style={{ width: "25%", marginRight: "20px" }}
                        />
                        <Box>
                            <Typography variant="h2" color={colors.grey[100]}>
                                No Orders Available
                            </Typography>
                            <Typography variant="subtitle1" color={colors.grey[300]}>
                                Currently, there are no orders made by you
                            </Typography>
                        </Box>
                    </Box>
                )}
            </Box>
            {/* Popup dialog */}
            {
                isOpen && (
                    <Box
                        position='fixed'
                        top='0'
                        left='0'
                        width='100%'
                        height='100%'
                        backgroundColor='rgba(0, 0, 0, 0.5)'
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                        zIndex='1000'
                    >
                        <Box
                            position='relative'
                            backgroundColor='#fff'
                            padding='20px'
                            borderRadius='8px'
                            width='600px'
                            maxWidth='90%'
                            boxShadow='0 4px 6px'
                        >
                            <Button
                                onClick={closeDialog}
                                sx={{
                                    position: 'absolute',
                                    top: '10px',
                                    right: '10px',
                                    backgroundColor: 'rgba(0, 0, 0, 0.54)',
                                    color: '#fff',
                                    border: 'none',
                                    fontSize: '24px',
                                    minWidth: "30px",
                                    height: "30px",
                                    borderRadius: "17px"
                                }}
                            >
                                &times;
                            </Button>
                            <Box className="row d-flex justify-content-center text-center">
                                <h5 className="mt-4"><strong>Bundle Consumption Details for {iccid}</strong></h5>
                                <Box mt={4} maxWidth="400px">
                                    <table className="table table-bordered table-striped">
                                        <tbody>
                                            <tr>
                                                <th>Description</th>
                                                <td>{description}</td>
                                            </tr>
                                            <tr>
                                                <th>Type</th>
                                                <td>Data</td>
                                            </tr>
                                            <tr>
                                                <th>Allocated</th>
                                                <td>{initial_quantity}</td>
                                            </tr>
                                            <tr>
                                                <th>Remaining</th>
                                                <td>{remaining_quantity}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                )
            }
        </Box >

    );
};

export default CheckUsage;