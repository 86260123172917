import { Box } from "@mui/material";
import Header from "./Header"

const DashboardContent = () => {
    return <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="Dashboard" subtitle="Welcome to your dashboard" />
            </Box>
        </Box>
}

export default DashboardContent