import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { Public, DataUsage, CalendarToday } from '@mui/icons-material';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';

const PackageCard = ({ product, onBuyNow }) => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            p={2.5}
            border="1px solid #ccc"
            borderRadius="8px"
            textAlign="left"
            sx={{
                background: 'linear-gradient(135deg, #c4cee0 20%, white 90%)',
                boxShadow: (theme) =>
                    theme.palette.mode === 'dark'
                        ? '2px 2px 8px rgba(255, 255, 255, 0.2)'
                        : '2px 2px 8px rgba(0, 0, 0, 0.2)',
            }}
        >
            <Box>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    p={2}
                    sx={{ borderBottom: '1px solid #938e8e' }}
                >
                    <Typography variant="h5" p={2} gutterBottom>
                        <strong>{product.package_name}</strong>
                    </Typography>
                    {product.country && product.country.flag_url ? (
                        <img
                            alt={product.country.name}
                            src={product.country.flag_url}
                            style={{ width: '70px', height: '40px' }}
                        />
                    ) : (
                        <span>No flag available</span>
                    )}
                </Box>

                {/* Coverage */}
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    p={2}
                    sx={{ borderBottom: '1px solid #938e8e' }}
                >
                    <Box display="flex" alignItems="center">
                        <Public sx={{ mr: 1 }} />
                        <Typography variant="body2">Coverage</Typography>
                    </Box>
                    <Typography variant="body2">
                        {Array.isArray(product.speed) ? product.speed.join(', ') : 'Not available'}
                    </Typography>
                </Box>

                {/* Data */}
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    p={2}
                    sx={{ borderBottom: '1px solid #938e8e' }}
                >
                    <Box display="flex" alignItems="center">
                        <DataUsage sx={{ mr: 1 }} />
                        <Typography variant="body2">Data</Typography>
                    </Box>
                    <Typography variant="body2">
                        {product.data_amount === -1 ? 'Unlimited' : `${product.data_amount} GB`}
                    </Typography>
                </Box>

                {/* Validity */}
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    p={2}
                    sx={{ borderBottom: '1px solid #938e8e' }}
                >
                    <Box display="flex" alignItems="center">
                        <CalendarToday sx={{ mr: 1 }} />
                        <Typography variant="body2">Validity</Typography>
                    </Box>
                    <Typography variant="body2">{product.duration} days</Typography>
                </Box>

                {/* Price */}
                <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
                    <Typography variant="body2">Price</Typography>
                    <Typography variant="body2">${(product.price * 1.2).toFixed(2)}</Typography>
                </Box>
            </Box>

            <Button variant="contained" color="primary" onClick={() => onBuyNow(product.id, product.package_name)}>
                Buy Now
            </Button>
        </Box>
    );
};

export default PackageCard;
