import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import PackageCard from '../../components/packageCard';

const PackagesGrid = () => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const createSlug = (text) => text.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/sl-products`);
                setProducts(response.data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchProducts();
    }, []);

    const handleBuyNow = (id, packageName) => {
        const slug = createSlug(packageName);
        navigate(`/product/${id}-${slug}`);
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error fetching products: {error}</div>;

    return (
        <Box>
            <Grid container spacing={3}>
                {products.length > 0 ? (
                    products.map((product) => (
                        <Grid item xs={12} sm={6} md={4} key={product.id}>
                            <PackageCard product={product} onBuyNow={handleBuyNow} />
                        </Grid>
                    ))
                ) : (
                    <Typography>No data packages available</Typography>
                )}
            </Grid>
        </Box>
    );
};

export default PackagesGrid;
