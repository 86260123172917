import React from "react";
import { Grid, Card, CardContent, Typography, Avatar, Box, Divider, useTheme } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import PublicIcon from "@mui/icons-material/Public";
import HomeIcon from "@mui/icons-material/Home";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import { tokens } from "../../theme";

const testimonials = [
  {
    name: "Leslie Alexander",
    role: "Freelance React Developer",
    feedback:
      "You made it so simple. My new site is so much faster and easier to work with than my old site. I just choose the page, make the change.",
    image:
      "https://cdn.rareblocks.xyz/collection/clarity/images/testimonial/4/avatar-male-1.png",
  },
  {
    name: "Jacob Jones",
    role: "Product Manager",
    feedback:
      "Rareblocks has helped our team save a lot of time and effort. It’s intuitive, flexible, and packed with great features.",
    image:
      "https://cdn.rareblocks.xyz/collection/clarity/images/testimonial/4/avatar-male-2.png",
  },
  {
    name: "Emily Carter",
    role: "UX Designer",
    feedback:
      "I love how it integrates seamlessly into my workflow. The design options are top-notch and very easy to use.",
    image:
      "https://cdn.rareblocks.xyz/collection/clarity/images/testimonial/4/avatar-female-1.png",
  },
];

const TestimonialsSection = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

  return (
    <Box>
      {/* Impact Section */}
      <Box py={8} sx={{
        background: colors.blueAccent[800],
      }}
      >
        <Box maxWidth="80%" mx="auto">
          <Box textAlign="center" mb={6}>
            <Typography
                    variant="h2"
                    sx={{
                        fontWeight: "bold",
                        marginBottom: "10px",
                        color: colors.grey[100],
                    }}
                >
                    Impact
                </Typography>
            <Typography variant="body1" color="textSecondary">
              At <strong>Travel Data eSIM</strong>, we strive to create a positive global
              impact by empowering travelers, promoting sustainability, and fostering
              inclusivity through seamless digital connectivity.
            </Typography>
          </Box>

          {/* Global Connectivity Section */}
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Box textAlign="center" p={4}>
                <SignalCellularAltIcon fontSize="large" color="primary" />
                <Typography variant="h5" fontWeight="bold" mt={2}>
                  Global Connectivity
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Reliable and affordable eSIM solutions in 200+ countries and regions,
                  connecting you to loved ones and opportunities without borders.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box textAlign="center" p={4}>
                <HomeIcon fontSize="large" color="success" />
                <Typography variant="h5" fontWeight="bold" mt={2}>
                  Driving Sustainability
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  We reduce plastic waste by replacing physical SIM cards with digital
                  solutions, promoting a greener future.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box textAlign="center" p={4}>
                <PublicIcon fontSize="large" color="action" />
                <Typography variant="h5" fontWeight="bold" mt={2}>
                  Global Impact
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Seamless connectivity that transforms lives and creates opportunities
                  for everyone, everywhere.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Divider />

      {/* Testimonials Section */}
      <Box py={8}>
        <Box maxWidth="80%" mx="auto">
          <Box textAlign="center" mb={6}>
            <Typography variant="h6" color="textSecondary" gutterBottom>
              2,157 people have said how good Rareblocks
            </Typography>
            <Typography variant="h4" fontWeight="bold">
              What our happy customers say
            </Typography>
          </Box>

          <Grid container spacing={4} justifyContent="center">
            {testimonials.map((testimonial, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card
                  elevation={3}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                  }}
                >
                  <CardContent>
                    <Box display="flex" alignItems="center" mb={2}>
                      {[...Array(5)].map((_, i) => (
                        <StarIcon key={i} color="warning" fontSize="small" />
                      ))}
                    </Box>
                    <Typography variant="body1" color="textSecondary" mb={3}>
                      {testimonial.feedback}
                    </Typography>
                  </CardContent>
                  <Box display="flex" alignItems="center" p={2}>
                    <Avatar src={testimonial.image} alt={testimonial.name} />
                    <Box ml={2}>
                      <Typography variant="subtitle1" fontWeight="bold">
                        {testimonial.name}
                      </Typography>
                      <Typography variant="subtitle2" color="textSecondary">
                        {testimonial.role}
                      </Typography>
                    </Box>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default TestimonialsSection;
