import { Box, IconButton, useTheme, Typography, Menu, MenuItem } from "@mui/material";
import { useContext, useState, useEffect } from "react";
import { ColorModeContext, tokens } from "../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import StorefrontIcon from "@mui/icons-material/Storefront";

const Topbar = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);
    const navigate = useNavigate();

    const [user, setUser] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);

    // Function to check the user login status
    const checkUserStatus = () => {
        const token = localStorage.getItem("jwtToken"); // Update to use jwtToken
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                setUser(decodedToken);
            } catch (error) {
                console.error("Invalid token:", error);
                setUser(null);
            }
        } else {
            setUser(null);
        }
    };

    // Check user status on component mount and when local storage changes
    useEffect(() => {
        checkUserStatus();
        const intervalId = setInterval(checkUserStatus, 1000);
        return () => clearInterval(intervalId);
    }, []);

    // Handle Profile Menu Open
    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        localStorage.removeItem("jwtToken");
        setUser(null);
        setAnchorEl(null);
        navigate("/login");
    };

    const handleProfileClick = () => {
        navigate("/dashboard/profile");
        setAnchorEl(null);
    };

    const handlelogoClick = () => {
        navigate("/");
    };

    const handleStoreClick = () => {
        navigate("/buy-esim");
    };

    const menuItemStyles = {
        padding: '10px 20px',
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '&.Mui-selected': {
            backgroundColor: 'transparent',
        },
    };

    return( 
        <Box sx={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.05)" }}>
            <Box display="flex" justifyContent="space-between" p={2} sx={{ width: "80%", margin: "0 auto" }}>
                {/* Logo and store icon */}
                <Box display="flex" justifyContent="center" alignItems="center">
                    <img
                        alt="site-logo"
                        width="auto"
                        height="60px"
                        src={`../../assets/esim-brand-logo.png`}
                        onClick={handlelogoClick}/>
                    <Typography variant="body1" color={colors.grey[400]} sx={{ mx: 1 }}>
                        |
                    </Typography>
                    <IconButton onClick={handleStoreClick} sx={{ ml: 2 }}>
                            <StorefrontIcon style={{ color: colors.grey[100] }} /> 
                    </IconButton>
                    <Typography variant="h5">Store</Typography>
                </Box>
        
                { /* Icons */}
                <Box display="flex" alignItems="center">
                    <IconButton onClick={colorMode.toggleColorMode}>
                        {theme.palette.mode === "dark" ? (
                            < DarkModeOutlinedIcon />
                        ): (
                            <LightModeOutlinedIcon />
                        )}
                    </IconButton>

                    <Typography variant="body1" color={colors.grey[400]} sx={{ mx: 1 }}>
                        |
                    </Typography>

                    <Box display="flex" alignItems="center">
                        {user ? (
                            <>
                                <Box display="flex"  alignItems="center" marginLeft="20px">
                                    <IconButton style={{ padding:0 }}>
                                        <PersonOutlinedIcon style={{ color: colors.grey[400] }} />
                                    </IconButton>
                                    <Typography
                                        variant="h6"
                                        color={colors.grey[400]}
                                        onClick={handleMenuOpen}
                                        style={{ cursor: "pointer", marginLeft: "10px" }}
                                    >
                                        My Account
                                    </Typography>
                                </Box>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleMenuClose}
                                    sx={{
                                        '& .MuiPaper-root': {
                                            backgroundColor: colors.primary[400],
                                            borderRadius: '8px',
                                            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                                            padding: '8px',
                                            width: '180px',
                                        },
                                    }}
                                >
                                    <MenuItem sx={menuItemStyles} onClick={handleProfileClick}>Profile</MenuItem>
                                    <MenuItem sx={menuItemStyles} onClick={handleLogout}>Logout</MenuItem>
                                </Menu>
                            </>
                        ) : (
                            <>
                                <Typography variant="h6" color={colors.grey[100]}
                                    onClick={() => navigate("/login")}
                                    style={{ cursor: "pointer", marginRight: "5px" }}
                                >
                                    LOGIN
                                </Typography>
                                <span>/</span>
                                <Typography variant="h6" color={colors.grey[100]}
                                    onClick={() => navigate("/signup")}
                                    style={{ cursor: "pointer", marginLeft: "5px" }}
                                >
                                    SIGNUP
                                </Typography>
                            </>
                        )}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Topbar;
