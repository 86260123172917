import React, { useEffect, useState } from "react";
import { Button, Box, Typography, useTheme, CircularProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../dashboard/Header";

const Order = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const userId = localStorage.getItem('userid'); // Replace with the actual user ID (e.g., from Redux or Context)

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_BACKEND_API_BASE_URL}/user/orders/${userId}`
                );
                if (!response.ok) {
                    throw new Error("Failed to fetch orders");
                }
                const data = await response.json();
                setOrders(data); // Update state with fetched orders
            } catch (err) {
                console.error("Error fetching orders:", err.message);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchOrders();
    }, [userId]);

    const handleDownload = async (orderId) => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_BACKEND_API_BASE_URL}/user/orders/${orderId}/qr`,
                {
                    method: "GET",
                }
            );
    
            if (!response.ok) {
                throw new Error("Failed to fetch download file");
            }
    
            // Since the response is a file, we can directly process the blob
            const blob = await response.blob();  // Get the file as a blob
    
            // Generate a URL for the blob to use for downloading
            const downloadUrl = URL.createObjectURL(blob);
    
            // Create an invisible link to trigger the download
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.download = `Order${orderId}.zip`; // Set the filename (e.g., "Order123.zip")
    
            // Programmatically click the link to trigger the download
            link.click();
    
            // Revoke the object URL after the download starts
            URL.revokeObjectURL(downloadUrl);
        } catch (error) {
            console.error("Error downloading file:", error.message);
        }
    };
    

    const columns = [
        { field: "id", headerName: "Order Number", width: 150 },
        {
            field: "date",
            headerName: "Date",
            type: "text",
            headerAlign: "left",
            align: "left",
            width: 180,
        },
        {
            field: "packageName",
            headerName: "Package",
            flex: 1, // Ensures this column takes up remaining space
            cellClassName: "name-column--cell",
        },
        {
            field: "price",
            headerName: "Price",
            width: 150, // Set a fixed width for the price column
        },
        {
            field: "actions",
            headerName: "Actions",
            width: 200, // Set enough width for the button
            renderCell: (params) => (
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: '#3da58a',
                        color: 'white',
                        fontSize: '0.68rem', // Fix typo here: 0.6.5rem to 0.875rem
                        fontWeight: 'bold',
                        textTransform: 'none',
                        borderRadius: '8px',
                        padding: '8px 8px',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                        transition: 'background-color 0.3s ease',
                        '&:hover': {
                            backgroundColor: '#319177',
                        },
                        margin: '2px', // Add spacing between buttons
                    }}
                    onClick={(event) => {
                        event.stopPropagation(); // Prevent row selection
                        handleDownload(params.row.id); // Call download function
                    }}
                >
                    Download QR
                </Button>
            ),
        },
    ];

    return (
        <Box m="20px">
            <Header title="Orders" subtitle="All the orders have been made" />
            <Box
    m="40px 0 0 0"
    height="65vh"
    sx={{
        "& .MuiDataGrid-root": {
            border: "none",
        },
        "& .MuiDataGrid-cell": {
            borderBottom: "none", // Remove the default border on cells
            outline: "none", // Prevent focus outline on the cell itself
        },
        "& .MuiDataGrid-columnHeader": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
        },
        // Prevent focus styles on cell when clicked
        "& .MuiDataGrid-cell:focus-visible": {
            outline: "none", // Remove any focus outline
            boxShadow: "none", // Prevent box-shadow on cell focus
        },
        // Prevent border on button focus
        "& .MuiButton-root:focus": {
            outline: "none", // Remove outline on button focus
            boxShadow: "none", // Remove box-shadow on button focus
        },
    }}
>
                {loading ? (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                    >
                        <CircularProgress color="secondary" />
                    </Box>
                ) : error ? (
                    <Typography variant="h6" color="error" textAlign="center">
                        {error}
                    </Typography>
                ) : orders.length > 0 ? (
                    <DataGrid
                        rows={orders}
                        columns={columns}
                        getRowId={(row) => row.id} // Use `id` as the row ID
                    />
                ) : (
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                        textAlign="left"
                    >
                        <img
                            src={`../../assets/no_orders_yet_searching.png`}
                            alt="No data available"
                            style={{ width: "25%", marginRight: "20px" }}
                        />
                        <Box>
                            <Typography variant="h2" color={colors.grey[100]}>
                                No Orders Available
                            </Typography>
                            <Typography variant="subtitle1" color={colors.grey[300]}>
                                Currently, there are no orders made by you
                            </Typography>
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default Order;
