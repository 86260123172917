import { Box, Typography, Button, useTheme } from "@mui/material";
import { tokens } from "../../theme";

const AboutUs = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box
            sx={{
                width: "100%",
                minHeight: "100vh",
                padding: "40px 20px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "40px",
                // backgroundImage: `linear-gradient(145deg, ${colors.grey[800]} 20%, ${colors.grey[900]} 80%)`,
            }}
        >
            {/* Header Section */}
            <Box
                sx={{
                    textAlign: "center",
                    padding: "20px",
                    maxWidth: "800px",
                }}
            >
                <Typography
                    variant="h2"
                    sx={{
                        fontWeight: "bold",
                        marginBottom: "10px",
                        color: colors.grey[100],
                    }}
                >
                    About Travel Data eSIM
                </Typography>
                <Typography
                    variant="h6"
                    sx={{
                        fontSize: "1.2rem",
                        color: colors.greenAccent[400],
                        opacity: 0.8,
                    }}
                >
                    Stay Connected Effortlessly
                </Typography>
            </Box>

            {/* Main Content Section */}
            <Box
                sx={{
                    maxWidth: "1200px",
                    display: "grid",
                    gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
                    gap: "40px",
                }}
            >
                {/* Left Column */}
                <Box>
                    <Typography
                        variant="h4"
                        sx={{
                            fontWeight: "bold",
                            marginBottom: "20px",
                            color: colors.grey[100],
                        }}
                    >
                        Tired of High Roaming Charges?
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            fontSize: "1.1rem",
                            lineHeight: "1.8",
                            marginBottom: "20px",
                            color: colors.grey[100],
                        }}
                    >
                        Travel Data eSIM offers a hassle-free solution to stay connected while traveling abroad. 
                        Our eSIMs provide affordable, high-speed data, making it easy to stay connected to family, 
                        friends, and work, no matter where you are.
                    </Typography>
                    <Typography
                        variant="h4"
                        sx={{
                            fontWeight: "bold",
                            marginBottom: "20px",
                            color: colors.grey[100],
                        }}
                    >
                        The eSIM Solution
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            fontSize: "1.1rem",
                            lineHeight: "1.8",
                            color: colors.grey[100],
                        }}
                    >
                        Travel Data eSIM offers a simple, affordable, and hassle-free way to stay connected no 
                        matter where you are. With our eSIMs (digital SIM cards), you can instantly download data 
                        packs for over 200 countries and regions worldwide. No need for physical SIM cards or 
                        visiting vendors — just activate your eSIM and stay connected the moment you arrive at your 
                        destination.
                    </Typography>
                </Box>

                {/* Right Column */}
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "20px",
                        background: `linear-gradient(145deg, ${colors.grey[800]} 10%, ${colors.grey[900]} 90%)`,
                        borderRadius: "12px",
                        boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.2)",
                        textAlign: "center",
                    }}
                >
                    <Typography
                        variant="h4"
                        sx={{
                            fontWeight: "bold",
                            marginBottom: "20px",
                            color: colors.grey[100],
                        }}
                    >
                        Our Mission
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            fontSize: "1.1rem",
                            lineHeight: "1.8",
                            color: colors.grey[100],
                            opacity: 0.9,
                        }}
                    >
                        At Travel Data eSIM, our mission is to make global connectivity effortless and accessible 
                        to every traveler. We are committed to providing affordable, reliable, and innovative 
                        solutions that empower you to explore the world without limits. Whether it’s for work or 
                        adventure, our goal is to keep you connected, so you can focus on what truly matters—your 
                        journey.
                    </Typography>
                </Box>
            </Box>

            {/* Call to Action */}
            <Box
                sx={{
                    textAlign: "center",
                    marginTop: "40px",
                }}
            >
                <Typography
                    variant="h5"
                    sx={{
                        marginBottom: "20px",
                        color: colors.grey[100],
                    }}
                >
                    Say goodbye to connectivity worries and enjoy your travels with Travel Data eSIM!
                </Typography>
            </Box>
        </Box>
    );
};

export default AboutUs;
