import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { Box, IconButton, Typography, useTheme, List, ListItem, ListItemButton } from "@mui/material"
import { ColorModeContext, tokens } from "../theme";
import { InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search"
import CountryGrid from "../components/countryGrid";
import DataPackageGrid from "../scenes/home/packagesGrid";
import axios from 'axios';


const Home = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    // const colorMode = useContext(ColorModeContext);
    const [countries, setCountries] = useState([]);
    const [input, setInput] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/countries/ungrouped`);
                setCountries(response.data);
            } catch (err) {
                console.error(err);
                // } finally {
                //     setLoading(false);
            }
        };
        fetchCountries();
    }, []);


    const handleInputChange = (event) => {
        const value = event.target.value;
        setInput(value);

        // Filter suggestions based on input
        if (value) {
            setSuggestions(countries.filter((country) => country.name.toLowerCase().includes(value.toLowerCase())));
        } else {
            setSuggestions([]);
        }
    };

    const handleSuggestionClick = (name) => {
        // Navigate to buy-esim page with the clicked suggestion as parameter
        navigate(`/buy-esim?country=${encodeURIComponent(name)}`);
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter" && suggestions.length > 0) {
            // Autofill with the first suggestion
            const firstSuggestion = suggestions[0].name;
            navigate(`/buy-esim?country=${encodeURIComponent(firstSuggestion)}`);
        }
    };

    const handleSearch = () => {
        if (input) {
            // Navigate to buy-esim page with the input as a parameter
            navigate(`/buy-esim?country=${encodeURIComponent(input)}`);
        }
    };

    return (
        <Box
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                overflowY: 'auto',
                padding: '20px',
                width: '100%',
            }}
        >
            <Box
                className="innerBoxContainer"
                maxWidth="1000px"
                display="flex"
                flexDirection="column"
                alignItems="center"
                p={2}
                mb={4}
            >
                {/* Main Content Section */}
                <Box textAlign="center" mb={4}>
                    <Typography variant="h2" color={colors.grey[100]}>
                        Stay Connected with your loved ones on Your Journeys, All at Affordable Rates!
                    </Typography>
                    <Typography variant="h4" color={colors.grey[100]} mt={1} mb={3}>
                        Experience seamless connectivity with our affordable eSIM solutions.
                    </Typography>
                </Box>

                <Box
                    display="flex"
                    position="relative"
                    width="100%"
                    alignItems="center"
                    justifyContent="center"
                >
                    {/* Search Bar Section */}
                    <Box
                        display="flex"
                        backgroundColor={colors.primary[400]}
                        borderRadius="3px"
                        width="100%"
                        maxWidth="600px"
                        mb={4}
                    >
                        <InputBase
                            value={input}
                            onChange={handleInputChange}
                            onKeyDown={handleKeyDown}
                            sx={{ ml: 2, flex: 1, color: colors.grey[100], minHeight: "45px" }}
                            placeholder="Search for a country"
                            inputProps={{ 'aria-label': 'search' }}
                        />
                        <IconButton type="button" onClick={handleSearch} sx={{ p: 1 }}>
                            <SearchIcon sx={{ color: colors.grey[100] }} />
                        </IconButton>

                        {/* Display suggestions */}
                        {suggestions.length > 0 && (
                            <List sx={{ bgcolor: "background.paper", mt: 1, position: "absolute", width: "100%", maxWidth: "600px", maxHeight: "500px", overflow: "auto", marginTop: "40px", zIndex: 10 }}>
                                {suggestions.map((country) => (
                                    <ListItem key={country.id} disablePadding>
                                        <ListItemButton onClick={() => handleSuggestionClick(country.name)}>
                                            {country.name}
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </List>
                        )}
                    </Box>
                </Box>
            </Box>

            {/* Country Grid Section */}
            <Box className="innerBoxContainer" >
                <Typography variant="h2" className="innerTitleContainer">
                    Popular Countries
                </Typography>
                <CountryGrid />
            </Box>

            {/* Country Package Section */}
            <Box className="innerBoxContainer" >
                <Typography variant="h2" className="innerTitleContainer">
                    Packages
                </Typography>
                <DataPackageGrid />
            </Box>

            <Box className="innerBoxContainer" sx={{ padding: "20px" }}>
    <Typography variant="h2" className="innerTitleContainer" sx={{ textAlign: "center", marginBottom: "20px" }}>
        How TravelData Works
    </Typography>
    <Box
        display="grid"
        gridTemplateColumns={{
            xs: "repeat(2, 1fr)", // Single column for small screens
            sm: "repeat(4, 1fr)", // Two columns for medium screens
            md: "repeat(4, 1fr)", // Four columns for larger screens
        }}
        gap={6}
    >
        <Box textAlign="center">
            <img
                alt="steps_travel_data"
                src={`../assets/step_1_open_travelData.png`}
                style={{ width: "80%", height: "auto", margin: "0 auto" }}
            />
            <Typography variant="h5" className="subTitleContainer">Load e-sim TravelData site</Typography>
        </Box>

        <Box textAlign="center">
            <img
                alt="steps_travel_data"
                src={`../assets/step_2_choose_country_region.png`}
                style={{ width: "80%", height: "auto", margin: "0 auto" }}
            />
            <Typography variant="h5" className="subTitleContainer">
                Choose your destination and package
            </Typography>
        </Box>

        <Box textAlign="center">
            <img
                alt="steps_travel_data"
                src={`../assets/step_3_scan_qr_code.png`}
                style={{ width: "80%", height: "auto", margin: "0 auto" }}
            />
            <Typography variant="h5" className="subTitleContainer">Install your eSIM</Typography>
        </Box>

        <Box textAlign="center">
            <img
                alt="steps_travel_data"
                src={`../assets/step_4_use_esim_data.png`}
                style={{ width: "80%", height: "auto", margin: "0 auto" }}
            />
            <Typography variant="h5" className="subTitleContainer">Activate your eSIM</Typography>
        </Box>
    </Box>
</Box>


        </Box>
    );
}

export default Home;
